/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../redux/shop/shop.actions';
import Interceptor from '../../Services/Interceptor';
import { dateFormat } from '../../utils/utils';

export default function Orders() {
  const [orders, setOrders] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    Interceptor.get('/customer/orders?include=properties,media')
      .then(
        (res) => {
          setOrders(res?.data?.data?.results);
        }
      ).catch((err) => console.log(err));
  }, []);

  const handleAddToCartDispatch = async (product) => {
    const cartState = {
      product: product?.product?.id,
      color: product?.color,
      sizes: product?.sizes,

    };
    if (product && cartState) {
      dispatch(addToCart(product?.product?.id, cartState));
    }
  };

  return (
    <div>
      <div className="py-6">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold text-primary-magenta uppercase">MY Orders</h2>
        </div>
        <div className="mt-10 ">

          <div className=" bg-transparent sm:pb-16 lg:pb-20">
            <div className="mx-auto  ">
              <div className="flow-root">
                <ul className="-my-8    sm:-my-12 lg:-my-0 ">
                  {orders.length > 0 ? orders?.map((order) => (
                    <>
                      <li className=" flex lg:gap-4   py-8 ">
                        <div className="hidden lg:block">
                          <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="28" cy="28" r="28" fill="#FFEDF4" />
                            <g clipPath="url(#clip0_173_6357)">
                              <path d="M35.9853 22.2956H32.563V20.0141C32.563 18.804 32.0823 17.6434 31.2266 16.7876C30.3708 15.9319 29.2102 15.4512 28.0001 15.4512C26.7899 15.4512 25.6293 15.9319 24.7736 16.7876C23.9178 17.6434 23.4371 18.804 23.4371 20.0141V22.2956H20.0149C19.7123 22.2956 19.4222 22.4158 19.2083 22.6297C18.9943 22.8437 18.8741 23.1338 18.8741 23.4364V39.4067C18.8741 39.7093 18.9943 39.9994 19.2083 40.2134C19.4222 40.4273 19.7123 40.5475 20.0149 40.5475H35.9853C36.2878 40.5475 36.5779 40.4273 36.7919 40.2134C37.0058 39.9994 37.126 39.7093 37.126 39.4067V23.4364C37.126 23.1338 37.0058 22.8437 36.7919 22.6297C36.5779 22.4158 36.2878 22.2956 35.9853 22.2956ZM24.5778 20.0141C24.5778 19.1065 24.9384 18.236 25.5802 17.5943C26.222 16.9525 27.0924 16.5919 28.0001 16.5919C28.9077 16.5919 29.7782 16.9525 30.4199 17.5943C31.0617 18.236 31.4223 19.1065 31.4223 20.0141V22.2956H24.5778V20.0141ZM35.9853 39.9771V39.4067H20.0149V23.4364H23.4371V25.1475C23.4371 25.2987 23.4972 25.4438 23.6042 25.5508C23.7111 25.6577 23.8562 25.7178 24.0075 25.7178C24.1587 25.7178 24.3038 25.6577 24.4108 25.5508C24.5178 25.4438 24.5778 25.2987 24.5778 25.1475V23.4364H31.4223V25.1475C31.4223 25.2987 31.4824 25.4438 31.5893 25.5508C31.6963 25.6577 31.8414 25.7178 31.9927 25.7178C32.1439 25.7178 32.289 25.6577 32.396 25.5508C32.5029 25.4438 32.563 25.2987 32.563 25.1475V23.4364H35.9853V39.4067V39.9771Z" fill="#984063" stroke="#984063" />
                            </g>
                            <defs>
                              <clipPath id="clip0_173_6357">
                                <rect width="27.3778" height="27.3778" fill="white" transform="translate(14.3112 14.3105)" />
                              </clipPath>
                            </defs>
                          </svg>

                        </div>

                        <div className="flex-1">
                          <div className="sm:flex space-y-6 lg:items-center sm:items-start sm:justify-between">
                            <div className="lg:flex space-y-2.5 lg:space-y-0 justify-between lg:items-center lg:space-x-12">
                              <div className="space-y-1.5">
                                <p className="text-sm  text-primary-black">
                                  <span className="font-semibold text-gray-900">Order ID:</span>
                                  <span className="font-bold text-gray-900 ml-2">{order?.ref_id}</span>
                                </p>

                                <p className="text-sm font-medium text-gray-500">
                                  <span className="font-semibold text-gray-900"> Date:</span>

                                  {' '}
                                  {/* {order?.created_at} */}
                                  <span className="font-bold text-gray-900">
                                    {dateFormat(order?.created_at)}
                                    {' '}
                                  </span>
                                </p>
                              </div>

                            </div>

                            <p className="px-6 text-center uppercase py-2 text-sm font-semibold bg-transparent text-primary-magenta border border-primary-magenta rounded-full">

                              {order?.status}
                            </p>
                          </div>

                          <p className="text-sm  text-primary-magenta mt-7">Order details</p>

                          <ul className="space-y-8 md:space-y-6 mt-7">
                            <li className="sm:flex sm:items-start sm:justify-between sm:space-x-5">
                              <div className="flex items-stretch flex-1">
                                <div className="flex-shrink-0">
                                  <img
                                    className="object-cover rounded-lg w-16 h-14"
                                    src={order?.product?.media[0]?.file_thumbnail || 'https://via.placeholder.com/150/C4C4C4/000000/?text=kladika.com'}
                                    alt=""
                                  />
                                </div>

                                <div className="flex flex-col justify-between ml-5">
                                  <div className="flex-1">
                                    <p className="text-sm font-bold text-gray-900">{order?.product?.name}</p>
                                    <p className="mt-1.5 text-base font-semibold text-gray-900">
                                      KES
                                      {' '}
                                      {order?.product?.has_periodic_sale && (new Date(order?.product.periodic_sale_start_date) < new Date() && new Date(order?.product.periodic_sale_end_date) > new Date()) ? order?.product?.periodic_sale_price : order?.product?.price}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="flex items-baseline justify-start mt-5 space-x-5 sm:mt-0 sm:justify-end">
                                {/* <a href="#" title="" className="p-1 -m-1 text-sm font-medium text-gray-500 transition-all duration-200 rounded hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"> View Product </a> */}
                                <div className="flex items-center justify-center flex-1  space-x-2">
                                  {Object.entries(order?.sizes).map(([key]) => (
                                    <div key={key} className="rounded-full ">
                                      <span className="w-6 h-6 text-[11px] flex justify-center items-center  border  border-gray-900 rounded-full text-center">
                                        {' '}
                                        {key}
                                      </span>
                                    </div>
                                  ))}
                                  <span
                                    style={{
                                      width: '20px', height: '20px', backgroundColor: `${order?.color}`, border: `1px solid ${order?.color}`, borderRadius: '50%',
                                    }}
                                    aria-hidden="true"
                                    className="w-6 h-6 rounded-full "
                                  />
                                </div>

                                <button type="button" onClick={() => handleAddToCartDispatch(order)} title="" className="p-1 -m-1 text-xs font-semibold uppercase text-primary-magenta border-b border-primary-magenta transition-all duration-200   focus:outline-none "> Re-order </button>
                              </div>
                            </li>

                          </ul>
                        </div>

                      </li>
                      <hr className="my-7" />

                    </>
                  ))
                    : <h5 className="text-lg font-semibold text-center uppercase">No Orders!!</h5>}

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
