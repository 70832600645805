/* eslint-disable import/prefer-default-export */
export const ShopTypes = {

  FETCH_FEATURED_PRODUCT_PROCESS: 'FETCH_FEATURED_PRODUCT_PROCESS',
  FETCH_FEATURED_PRODUCT_SUCCESS: 'FETCH_FEATURED_PRODUCT_SUCCESS',

  FETCH_SIMILAR_PRODUCTS_PROCESS: 'FETCH_SIMILAR_PRODUCTS_PROCESS',
  FETCH_SIMILAR_PRODUCTS_SUCCESS: 'FETCH_SIMILAR_PRODUCTS_SUCCESS',

  FETCH_SINGLE_PRODUCT_DETAILS_PROCESS: 'FETCH_SINGLE_PRODUCT_DETAILS_PROCESS',
  FETCH_SINGLE_PRODUCT_DETAILS_SUCCESS: 'FETCH_SINGLE_PRODUCT_DETAILS_SUCCESS',

  FETCH_PRODUCT_CATEGORIES_PROCESS: 'FETCH_PRODUCT_CATEGORIES_PROCESS',
  FETCH_PRODUCT_CATEGORIES_SUCCESS: 'FETCH_PRODUCT_CATEGORIES_SUCCESS',

  FETCH_PRODUCT_CATEGORY_PROCESS: 'FETCH_PRODUCT_CATEGORY_PROCESS',
  FETCH_PRODUCT_CATEGORY_SUCCESS: 'FETCH_PRODUCT_CATEGORY_SUCCESS',

  FETCH_PRODUCT_SUBCATEGORIES_PROCESS: 'FETCH_PRODUCT_SUBCATEGORIES_PROCESS',
  FETCH_PRODUCT_SUBCATEGORIES_SUCCESS: 'FETCH_PRODUCT_SUBCATEGORIES_SUCCESS',

  FETCH_PRODUCTS_PROCESS: 'FETCH_PRODUCTS_PROCESS',
  FETCH_PRODUCTS_SUCCESS: 'FETCH_PRODUCTS_SUCCESS',

  FETCH_ALL_CATEGORIES_PROCESS: 'FETCH_ALL_CATEGORIES_PROCESS',
  FETCH_ALL_CATEGORIES_SUCCESS: 'FETCH_ALL_CATEGORIES_SUCCESS',

  FETCH_ALL_SUBCATEGORIES_PROCESS: 'FETCH_ALL_SUBCATEGORIES_PROCESS',
  FETCH_ALL_SUBCATEGORIES_SUCCESS: 'FETCH_ALL_SUBCATEGORIES_SUCCESS',

  FETCH_POPULAR_PRODUCTS_PROCESS: 'FETCH_POPULAR_PRODUCTS_PROCESS',
  FETCH_POPULAR_PRODUCTS_SUCCESS: 'FETCH_POPULAR_PRODUCTS_SUCCESS',

  FETCH_WISHLIST_PRODUCTS_PROCESS: 'FETCH_WISHLIST_PRODUCTS_PROCESS',
  FETCH_WISHLIST_PRODUCTS_SUCCESS: 'FETCH_WISHLIST_PRODUCTS_SUCCESS',

  FETCH_CART_PRODUCTS_PROCESS: 'FETCH_CART_PRODUCTS_PROCESS',
  FETCH_CART_PRODUCTS_SUCCESS: 'FETCH_CART_PRODUCTS_SUCCESS',

  FETCH_CHECKOUT_QUOTE_PROCESS: 'FETCH_CHECKOUT_QUOTE_PROCESS',
  FETCH_CHECKOUT_QUOTE_SUCCESS: 'FETCH_CHECKOUT_QUOTE_SUCCESS',
};
