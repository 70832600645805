/* eslint-disable no-console */
import { getSearchParam, base64Decode, getCookie } from '../utils/utils';
import isAuthenticated from './isAuthenticated';

export function GuestGuard({ children }) {
  const authData = getCookie('auth:hash');
  const from = getSearchParam('redirect');

  if (authData && base64Decode(authData).success) {
    if (from) {
      window.location.href = from;
      return null;
    }
    window.location.href = '/';
    return null;
  }
  return <div>{children}</div>;
}

export function AuthGuard({ children }) {
  if (isAuthenticated() === true) {
    return <div className="bg-red">{children}</div>;
  }

  if (window.location.pathname !== '/') {
    const redirectUrl = encodeURIComponent(window.location.href);

    // Wait for the current url to be stored in redirectUrl before redirecting
    setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_CN_BASE_URL}/login?redirect=${redirectUrl}`;
    }, 100);
  }
  // window.location.href = '/login';
  // return <div>{children}</div>;
  // if (window.location.pathname !== '/') {
  //   const redirectUrl = encodeURIComponent(window.location.href);

  //   // Wait for the current url to be stored in redirectUrl before redirecting
  //   setTimeout(() => {
  //     window.location.href = `${process.env.REACT_APP_CN_BASE_URL}/login?redirect=${redirectUrl}`;
  //   }, 100);
  // }
}
