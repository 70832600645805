/* eslint-disable default-param-last */
import { USER_TYPES, USER_STATES } from './user.types';

const INITIAL_STATE = {
  user: {},
  loading: false,
  error: null,
  state: USER_STATES.LOGOUT
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_TYPES.UPDATE_USER_DETAILS_PROCESS:
      return {
        ...INITIAL_STATE,
        loading: true,
        error: null,
        state: USER_STATES.UPDATE_USER_DETAILS,
        user: {}
      };
    case USER_TYPES.UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...INITIAL_STATE,
        loading: false,
        user: action.payload,
        state: USER_STATES.UPDATE_USER_DETAILS,
        error: null
      };
    case USER_TYPES.UPDATE_USER_DETAILS_FAILURE:
      return {
        ...INITIAL_STATE,
        loading: false,
        error: action.payload,
        state: USER_STATES.UPDATE_USER_DETAILS,
        user: {}
      };
    default:
      return state;
  }
};

export default userReducer;
