/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import {

  requestFetchAllCategory,
  requestFetchPopularProducts,
  requestLikeAModel,
  requestFetchWishList,
  requestFetchProductDetail,
  requestFetchFeaturedProducts,
  requestFetchSimilarProducts,
  requestFetchAllProducts,
  requestAddToCart,
  requestFetchCart,
  requestFetchCheckoutQuote,
  requestSearchProducts,
  requestFetchSubCategories
} from '../../Services/shop.requests';
import { alertActions } from '../alert/alert.actions';
// import { alertActions } from '../alert/alert.actions';
import { ShopTypes } from './shop.types';

/**
 *
 * Fetch featured listing under deals.

 */
export const fetchFeaturedProducts = () => async (dispatch) => {
  dispatch({
    type: ShopTypes.FETCH_FEATURED_PRODUCT_PROCESS
  });

  try {
    const { data: { data } } = await requestFetchFeaturedProducts();

    dispatch({
      type: ShopTypes.FETCH_FEATURED_PRODUCT_SUCCESS,
      payload: data
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err?.response?.data);
  }
};

export const fetchSimilarProducts = (product_id) => async (dispatch) => {
  dispatch({
    type: ShopTypes.FETCH_SIMILAR_PRODUCTS_PROCESS
  });

  try {
    const { data: { data: { results } } } = await requestFetchSimilarProducts(product_id);

    dispatch({
      type: ShopTypes.FETCH_SIMILAR_PRODUCTS_SUCCESS,
      payload: results
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err?.response?.data);
  }
};
/**
 * Fetch a single listing detail.
 *

 */
export const fetchSingleProduct = (productId) => async (dispatch) => {
  dispatch({
    type: ShopTypes.FETCH_SINGLE_PRODUCT_DETAILS_PROCESS
  });

  try {
    const { data: { data } } = await requestFetchProductDetail(productId);
    dispatch({
      type: ShopTypes.FETCH_SINGLE_PRODUCT_DETAILS_SUCCESS,
      payload: data
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err.response.data);
  }
};

export const fetchProducts = (next, prev, to_price, from_price, brand, size, category, order_by) => async (dispatch) => {
  // let page_no = users?.current_page || 1;

  dispatch({
    type: ShopTypes.FETCH_PRODUCTS_PROCESS
  });

  try {
    const { data: { data } } = await requestFetchAllProducts(next, prev, to_price, from_price, brand, size, category, order_by);
    dispatch({
      type: ShopTypes.FETCH_PRODUCTS_SUCCESS,
      payload: data
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};

export const searchProducts = (next, prev, keyword, to_price, from_price, brand, size, category, order_by) => async (dispatch) => {
  dispatch({
    type: ShopTypes.FETCH_PRODUCTS_PROCESS
  });

  try {
    const { data: { data } } = await requestSearchProducts(next, prev, keyword, to_price, from_price, brand, size, category, order_by);
    dispatch({
      type: ShopTypes.FETCH_PRODUCTS_SUCCESS,
      payload: data
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};

export const fetchPopularProducts = () => async (dispatch) => {
  dispatch({
    type: ShopTypes.FETCH_POPULAR_PRODUCTS_PROCESS
  });

  try {
    const { data: { data } } = await requestFetchPopularProducts();
    dispatch({
      type: ShopTypes.FETCH_POPULAR_PRODUCTS_SUCCESS,
      payload: data
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};

export const fetchAllCategory = () => async (dispatch) => {
  dispatch({
    type: ShopTypes.FETCH_ALL_CATEGORIES_PROCESS
  });

  try {
    const { data: { data } } = await requestFetchAllCategory();
    dispatch({
      type: ShopTypes.FETCH_ALL_CATEGORIES_SUCCESS,
      payload: data
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};

export const fetchAllSubCategory = () => async (dispatch) => {
  dispatch({
    type: ShopTypes.FETCH_ALL_SUBCATEGORIES_PROCESS
  });

  try {
    const { data: { data } } = await requestFetchSubCategories();
    dispatch({
      type: ShopTypes.FETCH_ALL_SUBCATEGORIES_SUCCESS,
      payload: data
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};

export const fetchWishlist = () => async (dispatch) => {
  dispatch({
    type: ShopTypes.FETCH_WISHLIST_PRODUCTS_PROCESS
  });

  try {
    const { data: { data } } = await requestFetchWishList();

    dispatch({
      type: ShopTypes.FETCH_WISHLIST_PRODUCTS_SUCCESS,
      payload: data
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};

export const likeProduct = (productId) => async (dispatch) => {
  try {
    const { data: { message } } = await requestLikeAModel(productId);
    // const listingCategoryId = location.pathname.split('/')[2];
    dispatch(fetchWishlist());

    dispatch(alertActions.success(message));
    // dispatch(alertActions.success(res.data.message));
  } catch (err) {
    if (err.response) {
      const { message } = err?.response?.data;
      if (err.response.status === 401) {
        dispatch(alertActions.error(message));

        window.location = '/login';
        if (err.response.status === 500) {
          // TODO: handle server error
          dispatch(alertActions.error(message));
        }
      }
    }
  }
};

export const fetchCart = () => async (dispatch) => {
  dispatch({
    type: ShopTypes.FETCH_CART_PRODUCTS_PROCESS
  });

  try {
    const { data: { data } } = await requestFetchCart();

    dispatch({
      type: ShopTypes.FETCH_CART_PRODUCTS_SUCCESS,
      payload: data
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};

export const addToCart = (productId, body) => async (dispatch) => {
  try {
    const { data: { message } } = await requestAddToCart(productId, body);
    dispatch(alertActions.success(message));
    dispatch(fetchCart());
  } catch (err) {
    const { message } = err?.response?.data;

    dispatch(alertActions.error(message));
  }
};

// export fetch checkoutquote
export const fetchCheckoutQuote = (body) => async (dispatch) => {
  dispatch({
    type: ShopTypes.FETCH_CHECKOUT_QUOTE_PROCESS
  });

  try {
    const { data: { data } } = await requestFetchCheckoutQuote(body);

    dispatch({
      type: ShopTypes.FETCH_CHECKOUT_QUOTE_SUCCESS,
      payload: data
    });
  } catch (err) {
    const { message } = err?.response?.data;
    // dispatch(alertActions.error(message));
    // eslint-disable-next-line no-console
    console.log(message);
  }
};
