/* eslint-disable no-unused-vars */
// import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

// import { userActions } from '../../redux/auth/auth.actions';
// import { profileActions } from '../../redux/user/user.actions';
import { shippingActions } from '../../redux/shipping/shipping.actions';
import Interceptor from '../../Services/Interceptor';

export default function AddShipping() {
  const dispatch = useDispatch();
  const { shippingAddress, loadingShipping } = useSelector((state) => state.shippingReducer);
  // const details = useSelector((state) => state.userReducer);
  const [disabled, setDisabled] = React.useState(true);
  const [data, setData] = React.useState({});
  const { shippingId } = useParams();

  useEffect(() => {
    if (shippingId) {
      Interceptor.get(`/customer/shipping/addresses/${shippingId}`)
        .then((res) => {
          setData(res.data.data);
        // dispatch(shippingActions.getShippingAddress());
        });
    }
  }, [shippingId]);

  const initialState = {
    city: '',
    country: '',
    state: '',
    full_address: '',
    street_address: '',
    contact_number: ''
  };

  const handleSubmit = (values, { resetForm }) => {
    // const redirect = getSearchParam('redirect');
    // dispatch(userActions.login(values));
    // dispatch(profileActions.updateUser(values));
    dispatch(shippingActions.AddShippingAddress(values));
    resetForm();
  };

  return (
    <div>
      <div className="py-6">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold text-primary-magenta uppercase">Add new ADDRESS</h2>
          {/* <button onClick={() => setDisabled(!disabled)} type="button" className="text-sm font-semibold text-primary-magenta">Edit details</button> */}
        </div>
        <Formik onSubmit={handleSubmit} initialValues={initialState}>
          {(props) => (
            <form onSubmit={props.handleSubmit} className="mt-10 ">
              <div className="grid grid-cols-1 gap-6">
                <label className="block">
                  <span className="text-gray-700 text-xs">Address</span>
                  <textarea
                    name="street_address"
                    onChange={props.handleChange}
                    value={props.values.street_address}
                    className="
                  mt-1
                  block
                  w-full
                  rounded-3xl
                  bg-transparent
                  border-gray-300
                  border
                  focus:border-primary-magenta focus:bg-white focus:ring-0
                  placeholder:text-xs
                  "
                    rows="3"
                  />

                </label>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                  <label className="block">
                    <span className="text-gray-700 text-xs">Country</span>
                    <input
                      name="country"
                      onChange={props.handleChange}
                      value={props.values.country}
                      type="text"
                      className="
                  mt-1
                  block
                  w-full
                  rounded-full
                  bg-transparent
                  border-gray-300
                  border
                  focus:border-primary-magenta focus:bg-white focus:ring-0
                  placeholder:text-xs
                "
                      placeholder="State / County"
                    />
                  </label>
                  <label className="block">
                    <span className="text-gray-700 text-xs">State / County</span>
                    <input
                      name="state"
                      onChange={props.handleChange}
                      value={props.values.state}
                      type="text"
                      className="
                  mt-1
                  block
                  w-full
                  rounded-full
                  bg-transparent
                  border-gray-300
                  border
                  focus:border-primary-magenta focus:bg-white focus:ring-0
                  placeholder:text-xs
                "
                      placeholder="State / County"
                    />
                  </label>
                  <label className="block">
                    <span className="text-gray-700 text-xs">City</span>
                    <input
                      name="city"
                      onChange={props.handleChange}
                      value={props.values.city}
                      type="text"
                      className="
                mt-1
                block
                w-full
                rounded-full
                bg-transparent
                border-gray-300
                border
                focus:border-primary-magenta focus:bg-white focus:ring-0
                placeholder:text-xs
              "
                      placeholder="City"
                    />
                  </label>
                  <label className="block">
                    <span className="text-gray-700 text-xs">Contact Number</span>
                    <input
                      name="contact_number"
                      onChange={props.handleChange}
                      value={props.values.contact_number}
                      type="text"
                      className="
                mt-1
                block
                w-full
                rounded-full
                bg-transparent
                border-gray-300
                border
                focus:border-primary-magenta focus:bg-white focus:ring-0
                placeholder:text-xs
              "
                      placeholder="Contact Number"
                    />
                  </label>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                  <div className="py-4">
                    <h2 className="text-sm font-bold text-gray-900 tracking-wide">How do you prefer we contact you?</h2>
                    <fieldset className="block mt-2">

                      <div className="mt-2 space-y-3">
                        <div>
                          <label className="inline-flex items-center text-sm">
                            <input className="form-radio text-primary-magenta outline-primary-black" type="radio" name="contact-radio" />
                            <span className="ml-2">Whatsapp</span>
                          </label>
                        </div>
                        <div>
                          <label className="inline-flex items-center text-sm">
                            <input className="form-radio text-primary-magenta outline-primary-black" type="radio" name="contact-radio" />
                            <span className="ml-2">Email</span>
                          </label>
                        </div>

                      </div>
                    </fieldset>

                  </div>
                  <div className="py-4">
                    <h2 className="text-sm font-bold text-gray-900 tracking-wide">Pick delivery time</h2>
                    <fieldset className="block mt-2">

                      <div className="mt-2 space-y-3">
                        <div>
                          <label className="inline-flex items-center text-sm">
                            <input className="form-radio text-primary-magenta outline-primary-black" type="radio" name="delivery-time" />
                            <span className="ml-2">10am - 1pm</span>
                          </label>
                        </div>
                        <div>
                          <label className="inline-flex items-center text-sm">
                            <input className="form-radio text-primary-magenta outline-primary-black" type="radio" name="delivery-time" />
                            <span className="ml-2">2pm - 5pm</span>
                          </label>
                        </div>

                      </div>
                    </fieldset>

                  </div>
                </div>

              </div>
              <button type="submit" className="inline-block rounded-full px-12 py-3 my-10 bg-primary-magenta text-white font-semibold text-xs leading-tight uppercase shadow-md  active:shadow-lg transition duration-150 ease-in-out">
                Save
              </button>
            </form>
          )}
        </Formik>

      </div>

    </div>
  );
}
