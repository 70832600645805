/* eslint-disable default-param-last */
import CART_TYPES from './cart.types';

const INITIAL_STATE = {
  isUpdated: false,
  from: ' ',
};

const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CART_TYPES.CART_UPDATED:
      return {
        ...INITIAL_STATE,
        isUpdated: action.payload.val,
        from: action.payload.from
      };
    default:
      return state;
  }
};

export default cartReducer;
