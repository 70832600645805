import { combineReducers } from 'redux';
import shopReducer from '../../redux/shop/shop.reducer';
import brandsReducer from '../../redux/brands/brands.reducers';
import authReducer from '../../redux/auth/auth.reducers';
import { alertReducer } from '../../redux/alert/alert.reducers';
import userReducer from '../../redux/user/user.reducers';
import shippingReducer from '../../redux/shipping/shipping.reducers';
import cartReducer from '../../redux/cart/cart.reducers';

const rootReducer = combineReducers({
  shopReducer,
  brandsReducer,
  authReducer,
  alertReducer,
  userReducer,
  shippingReducer,
  cartReducer
});

export default rootReducer;
