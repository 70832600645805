/* eslint-disable react/no-array-index-key */
import {
  Fragment, lazy, Suspense
} from 'react';
import {
  BrowserRouter, Route, Switch
} from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
// , toast
import { ToastContainer } from 'react-toastify';
// import LoadingScreen from '../Components/LoadingScreen/LoadingScreen';
import MainLayout from '../components/Layouts/mainLayout';
import AccountLayout from '../components/Layouts/AccountLayout';
import { AuthGuard } from './Guards';
import LoadingScreen from '../components/LoadingScreen/LoadingScreen';

// export default function ScrollToTop() {
//   const { pathname } = useLocation();
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);
//   return null;
// }

export const renderRoutes = (routes = []) => (
  <>
    <Suspense fallback={<LoadingScreen />}>
      {/* <ScrollToTop /> */}
      <BrowserRouter>
        <Switch>
          {routes.map((route, i) => {
            const Guard = route.guard || Fragment;
            const Component = route.component;
            const Layout = route.layout || Fragment;

            return (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                render={(props) => (
                  <Guard>
                    <Layout>
                      {route.routes ? (
                        renderRoutes(route.routes)
                      ) : (
                        <Component {...props} />
                      )}
                    </Layout>
                  </Guard>
                )}
              />
            );
          })}
        </Switch>
      </BrowserRouter>
    </Suspense>
    <ToastContainer />
  </>
);

export const routes = [
  {
    exact: true,
    path: '/',
    layout: MainLayout,
    component: lazy(() => import('../views/UserArea/Home'))
  },
  {
    exact: true,
    path: '/about-us',
    layout: MainLayout,
    component: lazy(() => import('../views/StaticPages/About'))
  },
  {
    exact: true,
    path: '/subscription-confirmation',
    layout: MainLayout,
    component: lazy(() => import('../views/StaticPages/SubscriptionConfirmation'))
  },
  {
    exact: true,
    path: '/size-guide',
    layout: MainLayout,
    component: lazy(() => import('../views/StaticPages/SizeGuide'))
  },
  {
    exact: true,
    path: '/contact-us',
    layout: MainLayout,
    component: lazy(() => import('../views/StaticPages/Contact'))
  },
  {
    exact: true,
    path: '/shipping-delivery',
    layout: MainLayout,
    component: lazy(() => import('../views/StaticPages/Delivery'))
  },
  {
    exact: true,
    path: '/terms-and-conditions',
    layout: MainLayout,
    component: lazy(() => import('../views/StaticPages/Terms'))
  },
  {
    exact: true,
    path: '/returns-policy',
    layout: MainLayout,
    component: lazy(() => import('../views/StaticPages/Return-policy'))
  },
  {
    exact: true,
    path: '/privacy-policy',
    layout: MainLayout,
    component: lazy(() => import('../views/StaticPages/Privacy-policy'))
  },
  {
    exact: true,
    path: '/soko-sale',
    layout: MainLayout,
    component: lazy(() => import('../views/StaticPages/SokoSale'))
  },
  {
    exact: true,
    path: '/sale-form',
    layout: MainLayout,
    component: lazy(() => import('../views/StaticPages/SaleForm'))
  },
  {
    exact: true,
    path: '/sell-on-kladika',
    layout: MainLayout,
    component: lazy(() => import('../views/StaticPages/SellOnKladika'))
  },
  {
    exact: true,
    path: '/shop',
    layout: MainLayout,
    component: lazy(() => import('../views/Shop/Shop'))
  },
  {
    exact: true,
    path: '/search',
    layout: MainLayout,
    component: lazy(() => import('../views/Shop/Search'))
  },
  {
    exact: true,
    path: '/product/:slug/:id',
    layout: MainLayout,
    component: lazy(() => import('../views/Shop/ProductDetails'))
  },
  {
    exact: true,
    path: '/brands',
    layout: MainLayout,
    component: lazy(() => import('../views/Brands/BrandList'))
  },
  {
    exact: true,
    path: '/brand/:slug/:id',
    layout: MainLayout,
    component: lazy(() => import('../views/Brands/BrandDetails'))
  },
  {
    exact: true,
    path: '/cart',
    layout: MainLayout,
    component: lazy(() => import('../views/Cart/cart'))
  },
  {
    exact: true,
    path: '/checkout',
    layout: MainLayout,
    component: lazy(() => import('../views/Cart/Checkout'))
  },
  {
    exact: true,
    path: '/payment',
    layout: MainLayout,
    component: lazy(() => import('../views/Cart/Payment'))
  },
  {
    exact: true,
    path: '/track-order',
    layout: MainLayout,
    component: lazy(() => import('../views/UserArea/TrackOrder'))
  },
  {
    exact: true,
    path: '/track-order/:id',
    layout: MainLayout,
    component: lazy(() => import('../views/UserArea/OrderHistory'))
  },
  {
    exact: true,
    path: '/order-confirmed/:id',
    layout: MainLayout,
    component: lazy(() => import('../views/UserArea/OrderSuccess'))
  },
  {
    exact: true,
    path: '/login',
    layout: MainLayout,
    component: lazy(() => import('../views/Auth/Login'))
  },
  {
    exact: true,
    path: '/forgot-password',
    layout: MainLayout,
    component: lazy(() => import('../views/Auth/forgotPassword'))
  },
  {
    exact: true,
    path: '/reset-password',
    layout: MainLayout,
    component: lazy(() => import('../views/Auth/resetPassword'))
  },
  {
    exact: true,
    path: '/logout',
    layout: MainLayout,
    component: lazy(() => import('../views/Account/Logout'))
  },
  {
    exact: true,
    path: '/register',
    layout: MainLayout,
    component: lazy(() => import('../views/Auth/Signup'))
  },
  {
    guard: AuthGuard,
    path: '/account',
    layout: AccountLayout,
    routes: [
      {
        exact: true,
        path: '/account/details',
        component: lazy(() => import('../views/Account/Details'))
      },
      {
        exact: true,
        path: '/account/shipping',
        component: lazy(() => import('../views/Account/Shipping'))
      },
      {
        exact: true,
        path: '/account/settings',
        component: lazy(() => import('../views/Account/Settings'))
      },
      {
        exact: true,
        path: '/account/shipping/new',
        component: lazy(() => import('../views/Account/AddShipping'))
      },
      {
        exact: true,
        path: '/account/shipping/:shippingId',
        component: lazy(() => import('../views/Account/EditShipping'))
      },
      {
        exact: true,
        path: '/account/shipping/new',
        component: lazy(() => import('../views/Account/AddShipping'))
      },

      {
        exact: true,
        path: '/account/orders',
        component: lazy(() => import('../views/Account/Orders'))
      },
    ]
  },
  {
    exact: true,
    path: '/wishlist',
    layout: MainLayout,
    component: lazy(() => import('../views/Account/Wishlist'))
  },
  // {
  //   exact: true,
  //   path: '*',
  //   routes: [
  //     {
  //       component: lazy(() => import('../Components/Errors/Notfound'))
  //     }
  //   ]
  // }
];
