/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
// import { Link } from "react-router-dom";
// import { ArrowRight } from 'connect-nigeria-3.0/src/assets/images/icons/arrow-right';

import { Link } from 'react-router-dom';
import Arrow from '../../assets/icons/Arrow';

function AsLink({
  href, className, children, target, showArrow, arrowColor, onClick
}) {
  return (
    <Link to={href} className={`${className}  cursor-pointer`} onClick={onClick} target={target}>

      {children}
      {showArrow ? <Arrow color={arrowColor} /> : null}
    </Link>
  );
}

function AsBtn({
  className, children, disabled = false, type = 'button', showArrow, arrowColor, onClick
}) {
  return (
    <button disabled={disabled} className={`${className}  cursor-pointer`} onClick={onClick} type={type}>
      {children}
      {showArrow ? <Arrow color={arrowColor} /> : null}
    </button>
  );
}

function AsTag({
  className, children, arrowColor, showArrow, onClick
}) {
  return (
    <span className={className} onClick={onClick}>
      {children}
      {showArrow ? <Arrow color={arrowColor} /> : null}
    </span>
  );
}
export default function Button(
  {
    variant, href, className, buttonRadius, widthClass, justifyClass, children, onClick, heightClass, target, type, showArrow, arrowColor, disabled
  }
) {
  let classes = 'bg-white-100 text-mainBlack-100 border border-mainGrey-default'; // default for green
  switch (variant) {
    case 'magenta':
      classes = 'bg-primary-magenta text-white border-0 rounded-3xl ';
      break;
    case 'magenta-outline':
      classes = 'bg-transparent text-primary-magenta border rounded-3xl border-primary-magenta';
      break;
    case 'transparent':
      classes = 'bg-transparent border roundex-3xl';
      break;
    case 'white':
      classes = 'bg-white text-primary-magenta rounded-3xl ';
      break;
    default:
      break;
  }
  const fullClassName = `${heightClass || 'h-10'}  inline-flex items-center py-1 px-2 lg:px-4 focus:outline-none 
  ${buttonRadius || 'rounded-3xl'}
  ${widthClass}
  ${justifyClass}
  ${classes} ${className || ''} `;
  return href
    ? (
      <AsLink
        className={fullClassName}
        href={href}
        target={target}
        showArrow={showArrow}
        arrowColor={arrowColor}
      >
        {children}
      </AsLink>
    ) : type
      ? (
        <AsBtn
          onClick={onClick}
          className={fullClassName}
          type={type}
          showArrow={showArrow}
          arrowColor={arrowColor}
          disabled={disabled}
        >
          {children}
        </AsBtn>
      ) : (
        <AsTag
          className={fullClassName}
          showArrow={showArrow}
          arrowColor={arrowColor}
        >
          {children}
        </AsTag>
      );
}
