/* eslint-disable default-param-last */
import { ShopTypes } from './shop.types';

const INITIAL_STATE = {
  popular_products: [],
  featured_products: [],
  similar_products: [],
  products: [],
  wishlist: [],
  loading: false,
  product: {},
  categories: [],
  category: {},
  sub_categories: [],
  cart: [],
  checkoutQuote: {}
};

const shopReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ShopTypes.FETCH_POPULAR_PRODUCTS_PROCESS:
      return { ...state, loading: true, popular_products: [] };
    case ShopTypes.FETCH_POPULAR_PRODUCTS_SUCCESS:
      return { ...state, loading: false, popular_products: payload };
    case ShopTypes.FETCH_FEATURED_PRODUCT_PROCESS:
      return { ...state, loading: true, featured_products: [] };
    case ShopTypes.FETCH_FEATURED_PRODUCT_SUCCESS:
      return { ...state, loading: false, featured_products: payload };
    case ShopTypes.FETCH_SIMILAR_PRODUCTS_PROCESS:
      return { ...state, loading: true, similar_products: [] };
    case ShopTypes.FETCH_SIMILAR_PRODUCTS_SUCCESS:
      return { ...state, loading: false, similar_products: payload };
    case ShopTypes.FETCH_WISHLIST_PRODUCTS_PROCESS:
      return { ...state, loading: true, wishlist: [] };
    case ShopTypes.FETCH_WISHLIST_PRODUCTS_SUCCESS:
      return { ...state, loading: false, wishlist: payload };
    case ShopTypes.FETCH_SINGLE_PRODUCT_DETAILS_PROCESS:
      return { ...state, loading: true, product: [] };
    case ShopTypes.FETCH_SINGLE_PRODUCT_DETAILS_SUCCESS:
      return { ...state, loading: false, product: payload };
    case ShopTypes.FETCH_PRODUCTS_PROCESS:
      return { ...state, loading: true, products: [] };
    case ShopTypes.FETCH_PRODUCTS_SUCCESS:
      return { ...state, loading: false, products: payload };
    case ShopTypes.FETCH_ALL_CATEGORIES_PROCESS:
      return { ...state, loading: true, categories: [] };
    case ShopTypes.FETCH_ALL_CATEGORIES_SUCCESS:
      return { ...state, loading: false, categories: payload };
    // fetch all subcategories
    case ShopTypes.FETCH_ALL_SUBCATEGORIES_PROCESS:
      return { ...state, loading: true, sub_categories: [] };
    case ShopTypes.FETCH_ALL_SUBCATEGORIES_SUCCESS:
      return { ...state, loading: false, sub_categories: payload };
    case ShopTypes.FETCH_CART_PRODUCTS_PROCESS:
      return { ...state, loading: true, cart: [] };
    case ShopTypes.FETCH_CART_PRODUCTS_SUCCESS:
      return { ...state, loading: false, cart: payload };
    case ShopTypes.FETCH_CHECKOUT_QUOTE_PROCESS:
      return { ...state, loading: true, checkoutQuote: {} };
    case ShopTypes.FETCH_CHECKOUT_QUOTE_SUCCESS:
      return { ...state, loading: false, checkoutQuote: payload };
    default:
      return state;
  }
};

export default shopReducer;
