/* eslint-disable import/prefer-default-export */
import { useDispatch } from 'react-redux';
import { base64Decode, getCookie } from '../utils/utils';
import { AUTH_TYPES } from '../redux/auth/auth.types';

// Check user logged in status and update the store
export function AuthStateCheck({ children }) {
  const dispatch = useDispatch();
  const authData = getCookie('auth:hash');

  if (authData && base64Decode(authData).success) {
    dispatch({
      type: AUTH_TYPES.LOGIN_SUCCESS,
      payload: base64Decode(authData)
    });
  } else {
    dispatch({
      type: AUTH_TYPES.LOGOUT_SUCCESS,
      payload: {}
    });
  }
  return children;
}
