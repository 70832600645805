/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import { ALERT_TYPES } from './alert.types';

const INITIAL_STATE = {
  message: null,
  type: ALERT_TYPES.CLEAR
};

export const alertReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ALERT_TYPES.SUCCESS:
      return {
        ...state,
        message: action.message,
        type: ALERT_TYPES.SUCCESS
      };
    case ALERT_TYPES.WARN:
      return {
        ...state,
        message: action.message,
        type: ALERT_TYPES.WARN
      };
    case ALERT_TYPES.ERROR:
      return {
        ...state,
        message: action.message,
        type: ALERT_TYPES.ERROR
      };
    case ALERT_TYPES.CLEAR:
      return {
        ...state,
        message: null,
        type: ALERT_TYPES.CLEAR
      };
    default:
      return state;
  }
};
