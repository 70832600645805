/* eslint-disable no-console */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-use-before-define */

// import { deleteCookie } from '../utils/utils';
import Interceptor from './Interceptor';

async function updateUser({
  first_name, last_name, email, phone_number
}) {
  return await Interceptor.patch('/customer/account', {
    first_name, last_name, email, phone_number

  })
    .then((res) => ({
      success: true,
      data: res.data
    }))
    .catch((err) => ({
      success: false,
      error: err.response.data
    }));
}
// eslint-disable-next-line import/prefer-default-export
export const userRequests = {
  updateUser
};
