import Footer from '../Footer/footer';
import CategoriesHeader from '../Headers/categoriesHeader';
import MainHeader from '../Headers/mainHeader';

export default function Layout({ children }) {
  return (
    <>
      <div className="lg:z-50 lg:top-0 lg:sticky">
        <MainHeader />
        <CategoriesHeader />
      </div>

      <main className="relative">
        <section className="relative  ">
          {children}

        </section>
      </main>
      <Footer />
    </>
  );
}
