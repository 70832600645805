/* eslint-disable default-param-last */
import { BrandTypes } from './brands.types';

const INITIAL_STATE = {
  featured_BRANDes: [],
  loading: false,
  BRAND: {},
  brands: [],
  categories: [],
  fetchFailed: false,
  brandDetails: {},
};

const brandsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case BrandTypes.FETCH_FEATURED_BRAND_PROCESS:
      return { ...state, loading: true, featured_BRANDes: [] };
    case BrandTypes.FETCH_FEATURED_BRAND_SUCCESS:
      return { ...state, loading: false, featured_BRANDes: payload };

    case BrandTypes.FETCH_SINGLE_BRAND_DETAIL_PROCESS:
      return { ...state, loading: true, BRAND: {} };
    case BrandTypes.FETCH_SINGLE_BRAND_DETAIL_SUCCESS:
      return { ...state, loading: false, BRAND: payload };
    case BrandTypes.FETCH_SINGLE_BRAND_DETAIL_FAILED:
      return {
        ...state, loading: false, fetchFailed: true, BRAND: payload
      };
    case BrandTypes.FETCH_ALL_BRANDS_PROCESS:
      return { ...state, loading: true, brands: [] };
    case BrandTypes.FETCH_ALL_BRANDS_SUCCESS:
      return { ...state, loading: false, brands: payload };
    default:
      return state;
  }
};

export default brandsReducer;
