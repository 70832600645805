/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { authRequests } from './auth.requests';
// import { history } from '../history';
// import { userActions } from '../redux/auth/auth.actions';
// import { store } from '../Store/store';
// import { Redirect } from 'react-router';
// import { authRequests } from './auth.requests';
import { getToken } from './helpers';
// import { authRequests } from './auth.requests';

const Interceptor = axios.create();

Interceptor.interceptors.request.use((config) => {
  config.baseURL = process.env.REACT_APP_API_HOST;
  config.headers.Authorization = getToken() && `Bearer ${getToken()}`;
  config.headers['Content-Type'] = 'application/json';
  config.headers.Accept = 'application/json';
  return config;
}, (error) => Promise.reject(error));

Interceptor.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === 'ECONNABORTED') {
      // TODO: Add toast message
      return error;
    }
    // const { config, message } = error;
    // const originalConfig = error.config;
    if (error.response) {
      if (error.response.status === 401) {
        // originalConfig._retry = true;
        // window.location = '/login';
        // store.dispatch(userActions.logout());
        // history.push('/login');
        authRequests.requestLogout();
        const currentUrl = encodeURIComponent(window.location.href);
        window.location.href = `/login?redirect=${currentUrl}`;
        return;
      }
    }
    return Promise.reject(error);
  }
);

export default Interceptor;
