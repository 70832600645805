import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ALERT_TYPES } from '../redux/alert/alert.types';
import { alertActions } from '../redux/alert/alert.actions';

const colorTypes = {
  SUCCESS: 'text-green-500',
  WARN: 'text-yellow-500',
  ERROR: 'text-red-500',
};

export default function Notify({ children }) {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.alertReducer);
  const [alert, setAlert] = React.useState({
    message: null,
    icon: null,
    color: ''
  });

  const timeout = 5000; // 5 seconds

  // Message will be cleared after 5 seconds
  React.useEffect(() => {
    switch (selector.type) {
      case ALERT_TYPES.SUCCESS:
        setAlert({
          message: selector.message,
          icon: '/icons/alert-success.svg',
          color: colorTypes.SUCCESS
        });
        setTimeout(() => {
          setAlert({
            message: null,
            icon: null,
            color: ''
          });
          dispatch(alertActions.clear());
        }, timeout);
        break;
      case ALERT_TYPES.WARN:
        setAlert({
          message: selector.message,
          icon: '/icons/alert-warning.svg',
          color: colorTypes.WARN
        });
        setTimeout(
          () => {
            setAlert({
              message: null,
              icon: null,
              color: ''
            });
            dispatch(alertActions.clear());
          },
          timeout
        );
        break;
      case ALERT_TYPES.ERROR:
        setAlert({
          message: selector.message,
          icon: '/icons/alert-error.svg',
          color: colorTypes.ERROR
        });
        setTimeout(
          () => {
            setAlert({
              message: null,
              icon: null,
              color: ''
            });
            dispatch(alertActions.clear());
          },
          timeout
        );
        break;
      case ALERT_TYPES.CLEAR:
        setAlert({
          message: null,
          icon: null,
          color: ''
        });
        break;
      default:
        break;
    }
  }, [selector]);

  return (
    <div>
      <div className={`alert-container ${alert.message ? 'open' : ''} `}>
        {alert.message && (
          <>
            <div className={`_icon ${alert.color}`}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>

            </div>
            <div className={`_text ${alert.color}`}>{alert.message}</div>
            <button type="button" className="_close" onClick={() => dispatch(alertActions.clear())}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          </>
        )}
      </div>
      {children}
    </div>
  );
}
