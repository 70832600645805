/* eslint-disable no-console */
import { Field, Formik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../redux/auth/auth.actions';
import { profileActions } from '../../redux/user/user.actions';
// import { alertActions } from '../../../redux/alert/alert.actions';

export default function Details() {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.authReducer);
  // const details = useSelector((state) => state.userReducer);
  const [disabled, setDisabled] = React.useState(true);
  const { user } = useSelector((state) => state.authReducer);

  const initialState = {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone_number: user.phone_number == null ? '' : user.phone_number,
    // profile_picture: null,
    // callbackUrl: process.env.REACT_APP_CN_BASE_URL
  };

  const ValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    last_name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
  });

  useEffect(() => {
    dispatch(userActions.user());
  }, []);

  const handleSubmit = (values) => {
    // const redirect = getSearchParam('redirect');
    // dispatch(userActions.login(values));
    dispatch(profileActions.updateUser(values));
  };

  return (
    <div>
      <div className="py-6">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold text-primary-magenta">MY DETAILS</h2>
          <button onClick={() => setDisabled(!disabled)} type="button" className="text-sm font-semibold text-primary-magenta">Edit details</button>
        </div>
        <Formik enableReinitialize="true" onSubmit={handleSubmit} initialValues={initialState} validationSchema={ValidationSchema}>
          {(props) => (
            <form onSubmit={props.handleSubmit} className="mt-10 ">
              <div className="grid grid-cols-1 gap-6">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                  <label className="block" htmlFor="first_name">
                    <span className="text-gray-700 text-xs">First name</span>
                    <Field
                      name="first_name"
                      type="text"
                      disabled={disabled}
                      className={`${disabled === true ? ' bg-gray-200 cursor-not-allowed' : ' bg-white'}   mt-1
                    block
                    w-full
                    rounded-full
                   
                    border-gray-300
                    border
                    text-sm
                    focus:border-primary-magenta focus:bg-white focus:ring-0
                    placeholder:text-xs`}
                      placeholder="First name"
                    />
                    <p className="text-red-500 text-sm">{props.errors.first_name}</p>

                  </label>
                  <label className="block">
                    <span className="text-gray-700 text-xs">Last Name</span>
                    <input
                      onChange={props.handleChange}
                      value={props.values.last_name}
                      name="last_name"
                      type="text"
                      disabled={disabled}
                      className={`${disabled === true ? ' bg-gray-200 cursor-not-allowed' : ' bg-white'}   mt-1
                    block
                    w-full
                    rounded-full
                   
                    border-gray-300
                    border
                    text-sm
                    focus:border-primary-magenta focus:bg-white focus:ring-0
                    placeholder:text-xs`}
                      placeholder="Last Name"
                    />

                    <p className="text-red-500 text-sm">{props.errors.last_name}</p>
                  </label>
                </div>

                <label className="block">
                  <span className="text-gray-700 text-xs">Email</span>
                  <input
                    onChange={props.handleChange}
                    value={props.values.email}
                    type="email"
                    name="email"
                    disabled={disabled}
                    className={`${disabled === true ? ' bg-gray-200 cursor-not-allowed' : ' bg-white'}   mt-1
                    block
                    w-full
                    rounded-full
                   
                    border-gray-300
                    border
                    text-sm
                    focus:border-primary-magenta focus:bg-white focus:ring-0
                    placeholder:text-xs`}
                    placeholder="Email"
                  />
                  <p className="text-red-500 text-sm">{props.errors.email}</p>

                </label>
                <label htmlFor="phone_number" className="block">
                  <span className="text-gray-700 text-xs">Phone</span>
                  <Field
                    name="phone_number"
                    type="tel"
                    disabled={disabled}
                    className={`${disabled === true ? ' bg-gray-200 cursor-not-allowed' : ' bg-white'}   mt-1
                    block
                    w-full
                    rounded-full
                   
                    border-gray-300
                    border
                    text-sm
                    focus:border-primary-magenta focus:bg-white focus:ring-0
                    placeholder:text-xs`}
                    placeholder="Phone Number"
                  />
                  <p className="text-red-500 text-sm">{props.errors.phone_number}</p>

                </label>

              </div>
              <button type="submit" className="inline-block rounded-full px-12 py-3 my-10 bg-primary-magenta text-white font-semibold text-xs leading-tight uppercase shadow-md  active:shadow-lg transition duration-150 ease-in-out">
                {/* <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24" /> */}
                <svg className={` ${selector.loading === true ? 'inline-flex ' : 'hidden'} animate-spin h-5 w-5 mr-2 feather feather-loader `} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <line x1="12" y1="2" x2="12" y2="6" />
                  <line x1="12" y1="18" x2="12" y2="22" />
                  <line x1="4.93" y1="4.93" x2="7.76" y2="7.76" />
                  <line x1="16.24" y1="16.24" x2="19.07" y2="19.07" />
                  <line x1="2" y1="12" x2="6" y2="12" />
                  <line x1="18" y1="12" x2="22" y2="12" />
                  <line x1="4.93" y1="19.07" x2="7.76" y2="16.24" />
                  <line x1="16.24" y1="7.76" x2="19.07" y2="4.93" />
                </svg>
                Save
              </button>
            </form>
          )}
        </Formik>

      </div>

    </div>
  );
}
