export const USER_TYPES = {
  UPDATE_USER_DETAILS_PROCESS: 'update_user_details_process',
  UPDATE_USER_DETAILS_SUCCESS: 'update_user_details_success',
  UPDATE_USER_DETAILS_FAILURE: 'update_user_details_failure',

};

export const USER_STATES = {
  UPDATE_USER_DETAILS: 'update_user_details',
};
