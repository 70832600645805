/* eslint-disable no-console */
// import jwtDecode from 'jwt-decode';
// import axios from '../services/axios';

export default () => {
  const expiry = sessionStorage.getItem('expiry');

  // check if token exists and is not expired
  if (expiry) {
    try {
      // const token = jwtDecode(accessToken);
      const time = Date.now().valueOf() / 1000;
      if (expiry < time) {
        return false;
      }
    } catch (error) {
      return false;
    }
    // axios.defaults.headers.common.Authorization = accessToken;

    return !!expiry;
  }
  return false;
};
// check if token exists and is not expired
