export default function PageTitle({ title, description }) {
  return (
    <div className="pageTitle ">
      <h1 className="text-base lg:text-xl font-semibold  text-white capitalize">
        {title}
      </h1>
      <p className={`${description ? ' text-[#D1D5DB] text-sm lg:text-base font-normal pt-4' : ''}`}>{description}</p>
    </div>
  );
}
