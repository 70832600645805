export const SHIPPING_TYPES = {
  UPDATE_SHIPPING_DETAILS_PROCESS: 'update_shipping_details_process',
  UPDATE_SHIPPING_DETAILS_SUCCESS: 'update_shipping_details_success',
  UPDATE_SHIPPING_DETAILS_FAILURE: 'update_shipping_details_failure',
  GET_SHIPPING_DETAILS_PROCESS: 'get_shipping_details_process',
  GET_SHIPPING_DETAILS_SUCCESS: 'get_shipping_details_success',
  GET_SHIPPING_DETAILS_FAILURE: 'get_shipping_details_failure'
};

export const SHIPPING_STATES = {
  UPDATE_SHIPPING_DETAILS: 'update_shipping_details',
  GET_SHIPPING_DETAILS: 'get_shipping_details'
};
