/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-use-before-define */
// import { Redirect } from 'react-router';
import { SHIPPING_TYPES } from './shipping.types';
import { alertActions } from '../alert/alert.actions';
import { shippingRequests } from '../../Services/shipping.requests';

export const shippingActions = {
  updateShippingAddress,
  getShippingAddress,
  AddShippingAddress
  // facebookLogin,
  // googleLogin
};

function getShippingAddress() {
  const process = () => ({
    type: SHIPPING_TYPES.GET_SHIPPING_DETAILS_PROCESS,
    payload: {}
  });
  const success = (payload) => ({
    type: SHIPPING_TYPES.GET_SHIPPING_DETAILS_SUCCESS,
    payload
  });
  const failure = (errors) => ({
    type: SHIPPING_TYPES.GET_SHIPPING_DETAILS_FAILURE,
    payload: errors
  });

  return async (dispatch) => {
    dispatch(process());
    const res = await shippingRequests.getShippingAddress();
    if (res.status === 200) {
      dispatch(success(res.data.data));
      // dispatch(userActions.user());
      // dispatch(alertActions.success(res.data.message));
    } else {
      dispatch(failure(res.error));
      dispatch(alertActions.error(res.error.message));
    }
  };
}

function updateShippingAddress(data) {
  const process = () => ({
    type: SHIPPING_TYPES.UPDATE_SHIPPING_DETAILS_PROCESS,
    payload: {}
  });
  const success = (payload) => ({
    type: SHIPPING_TYPES.UPDATE_SHIPPING_DETAILS_SUCCESS,
    payload
  });
  const failure = (errors) => ({
    type: SHIPPING_TYPES.UPDATE_SHIPPING_DETAILS_FAILURE,
    payload: errors
  });

  return async (dispatch) => {
    dispatch(process());

    const res = await shippingRequests.updateShippingAddress(data);
    if (res.success) {
      dispatch(success(res.data.data.user));
      // dispatch(userActions.user());
      dispatch(alertActions.success(res.data.message));
    } else {
      dispatch(failure(res.error));
      dispatch(alertActions.error(res.error.message));
    }
  };
}

function AddShippingAddress(data) {
  const process = () => ({
    type: SHIPPING_TYPES.UPDATE_SHIPPING_DETAILS_PROCESS,
    payload: {}
  });
  const success = (payload) => ({
    type: SHIPPING_TYPES.UPDATE_SHIPPING_DETAILS_SUCCESS,
    payload
  });
  const failure = (errors) => ({
    type: SHIPPING_TYPES.UPDATE_SHIPPING_DETAILS_FAILURE,
    payload: errors
  });

  return async (dispatch) => {
    dispatch(process());
    const res = await shippingRequests.addShippingAddress(data);
    if (res.success) {
      dispatch(success(res.data.data.user));
      dispatch(getShippingAddress());
      dispatch(alertActions.success(res.data.message));

      setTimeout(() => {
        window.location.href = '/account/shipping';
      }, 1000);
    } else {
      dispatch(failure(res.error));
      dispatch(alertActions.error(res.error.message));
    }
  };
}
