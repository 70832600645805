/* eslint-disable import/prefer-default-export */
export const BrandTypes = {
  FETCH_FEATURED_BRAND_PROCESS: 'FETCH_FEATURED_BRAND_PROCESS',
  FETCH_FEATURED_BRAND_SUCCESS: 'FETCH_FEATURED_BRAND_SUCCESS',

  FETCH_SINGLE_BRAND_DETAIL_PROCESS: 'FETCH_SINGLE_BRAND_DETAIL_PROCESS',
  FETCH_SINGLE_BRAND_DETAIL_SUCCESS: 'FETCH_SINGLE_BRAND_DETAIL_SUCCESS',
  FETCH_SINGLE_BRAND_DETAIL_FAILED: 'FETCH_SINGLE_BRAND_DETAIL_FAILED',

  FETCH_BRAND_CATEGORIES_PROCESS: 'FETCH_BRAND_CATEGORIES_PROCESS',
  FETCH_Brand_CATEGORIES_SUCCESS: 'FETCH_BRAND_CATEGORIES_SUCCESS',

  FETCH_ALL_BRANDS_PROCESS: 'FETCH_ALL_BRANDS_PROCESS',
  FETCH_ALL_BRANDS_SUCCESS: 'FETCH_ALL_BRANDS_SUCCESS'
};
