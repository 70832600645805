/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-use-before-define */
import axios from 'axios';
import { Redirect } from 'react-router';
// import { deleteCookie } from '../utils/utils';
import Interceptor from './Interceptor';

async function requestLogin(email, password) {
  return await Interceptor.post('/customer/login', {
    email,
    password,

  })
    .then((res) => ({
      success: true,
      data: res.data
    }))
    .catch((err) => ({
      success: false,
      error: err.response.data.message
    }));
}

async function requestLogout() {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('expiry');
  // return await Interceptor.post('/customer/logout')
  //   .then((res) => (
  //     <Redirect to="/login" />
  //   ));
  // eslint-disable-next-line react/jsx-indent
}

async function requestRegister(data) {
  return await Interceptor.post('/customer/register', {
    ...data,
    callbackUrl: window.location.origin
  })
    .then((res) => ({
      success: true,
      data: res.data
    }))
    .catch((err) => ({
      success: false,
      error: err.response.data
    }));
}

async function getUser() {
  return await Interceptor.get('/customer/account')
    .then((res) => ({
      success: true,
      data: res.data
    }))

    .catch((err) => ({
      success: false,
      error: err.response.data
    }
    ));
}

// TODO: Everything below this line
function requestSocialLogin(userId, access_token, provider) {
  const headers = {
    'Content-Type': 'application/json'
  };
  return axios
    .post(
      `${process.env.REACT_APP_API_USER_URL}/auth/social/${provider}`,
      {
        userId,
        access_token,
        callbackUrl: `${process.env.REACT_APP_CN_BASE_URL}/verify`
      },
      headers
    )
    .then((res) => res.data)
    .catch((error) => {
      const errData = {
        ...error.response.data,
        message: error.response.data.trace
          ? 'An error occured. Please try again.'
          : error.response.data.message
      };
      return errData;
    });
}

// eslint-disable-next-line import/prefer-default-export
export const authRequests = {
  requestLogin,
  requestSocialLogin,
  requestLogout,
  requestRegister,
  getUser
};
