/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import { Menu, Transition, Popover } from '@headlessui/react';
// import { Popover } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  Fragment, useEffect, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { cartActions } from '../../redux/cart/cart.actions';
import { fetchCart, fetchCheckoutQuote } from '../../redux/shop/shop.actions';
import Interceptor from '../../Services/Interceptor';
import Button from '../Button';

export default function CartMenu() {
  const getLocalStorage = JSON.parse(localStorage.getItem('cartItems'));
  const getSingleData = JSON.parse(localStorage.getItem('singleData'));
  const [cartItems, setCartItems] = useState([]);
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.shopReducer);
  const token = JSON.parse(sessionStorage.getItem('token'));
  const [cart_items, setCart_items] = useState(JSON.parse(localStorage.getItem('cartItems')));
  const [singleItem, setSingleItem] = useState(JSON.parse(localStorage.getItem('singleData')));
  const { cart, checkoutQuote } = useSelector((state) => state?.shopReducer);
  const isUpdated = useSelector((state) => state?.cartReducer);
  const [cartTotal, setCartTotal] = useState('');
  const [price, setPrice] = useState('');

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [singleMenu, setSingleMenu] = useState(false);
  const toggleMenu = () => {
    if (singleMenu === true) {
      setSingleMenu(!singleMenu);
    } else {
      setIsMenuOpen(!isMenuOpen);
    }
  };

  useEffect(() => {
    setSingleItem(singleItem);
    if (!token) {
      setCartItems(cart_items);
      setCartTotal(cart_items?.reduce((a, b) => {
        const sum = b?.product?.has_periodic_sale === true && (new Date(b?.product?.periodic_sale_start_date) < new Date() && new Date(b?.product?.periodic_sale_end_date) > new Date()) ? a + (b?.product?.periodic_sale_price * b?.total_quantity) : a + (b?.product?.price * b?.total_quantity);
        return sum;
      }, 0));
    } else {
      setCartItems(cart?.results);
      setCartTotal(cart?.results?.reduce((a, b) => {
        const sum = b?.product?.has_periodic_sale === true && (new Date(b?.product?.periodic_sale_start_date) < new Date() && new Date(b?.product?.periodic_sale_end_date) > new Date()) ? a + (b?.product?.periodic_sale_price * b?.total_quantity) : a + (b?.product?.price * b.total_quantity);
        return sum;
      }, 0));
    }
  }, [token, cart, cart_items, singleItem]);

  const handleRemoveFromCart = (product) => {
    // api to remove from cart
    if (!token) {
      let a = [];
      a = JSON.parse(localStorage.getItem('items')) || [];
      const updateCart = cartItems?.filter((item) => item?.id !== product.id);
      const updateItems = a.filter((item) => item.product !== product.id);
      setCartItems(cartItems?.filter((item) => item?.id !== product.id));
      localStorage.setItem('items', JSON.stringify(updateItems));
      localStorage.setItem('cartItems', JSON.stringify(updateCart));
      setCart_items(updateCart);
    } else {
      Interceptor.delete(`/customer/cart/${product.id}`).then((res) => {
        dispatch(fetchCart());
        dispatch(fetchCheckoutQuote());
      }).catch((err) => {

      });
    }

    // dispatch(fetchCart());
  };

  useEffect(() => {
    if (token) {
      dispatch(fetchCheckoutQuote());
    }
  }, [dispatch]);

  useEffect(() => {
    if (isUpdated.from === 'cart' && isUpdated.isUpdated === true) {
      setSingleMenu(false);
      setCart_items(JSON.parse(localStorage.getItem('cartItems')));
      setSingleItem(JSON.parse(localStorage.getItem('singleData')));
    }

    if (isUpdated.from === ' ' && isUpdated.isUpdated === true) {
      setSingleMenu(isUpdated.isUpdated);
      setCart_items(JSON.parse(localStorage.getItem('cartItems')));
      setSingleItem(JSON.parse(localStorage.getItem('singleData')));
    }
    dispatch(cartActions.localStorageUpdate(false));
  }, [isUpdated.isUpdated]);

  // useEffect(() => {
  //   if (isUpdated.isUpdated === true) {
  //     dispatch(cartActions.localStorageUpdate(false));
  //   }
  // }, [isUpdated.isUpdated]);
  // console.log(checkoutQuote, ' checkoutQuote', cart, cart?.results?.reduce((a, b) => a + (b.product.periodic_sale_price + b.product.price), 0));
  return (
    <div className="">
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            <Menu as="div" onClick={() => toggleMenu()} className="inline-flex w-full items-center justify-center   ">
              <Link to="/cart" title="cart" className="text-base font-normal text-primary-black transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 "> Cart </Link>

              <div className="flex relative items-center justify-end ml-auto space-x-6">

                <div className="peer relative inline-flex items-center justify-center text-white transition-all duration-200 rounded-full w-9 h-9 md:w-12 md:h-12 ">
                  <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.7986 18.725C15.8377 18.7315 15.8766 18.7347 15.915 18.7347C16.2525 18.7347 16.5504 18.4909 16.6077 18.147L17.3109 13.9283C17.3747 13.5452 17.116 13.1829 16.7329 13.1191C16.3498 13.0551 15.9876 13.314 15.9237 13.6971L15.2206 17.9158C15.1568 18.2989 15.4155 18.6612 15.7986 18.725Z" fill="#1C2333" />
                    <path d="M7.43937 18.1469C7.49669 18.4909 7.79454 18.7346 8.13208 18.7346C8.17048 18.7346 8.20938 18.7315 8.24852 18.7249C8.63159 18.6611 8.89034 18.2988 8.82649 17.9158L8.12337 13.697C8.05952 13.3139 7.69732 13.0552 7.31421 13.119C6.93115 13.1829 6.6724 13.5451 6.73624 13.9282L7.43937 18.1469Z" fill="#1C2333" />
                    <path d="M23.2969 7.48437H20.8223L15.4084 0.717023C15.1659 0.413883 14.7234 0.364617 14.4202 0.607242C14.1169 0.84982 14.0677 1.29227 14.3104 1.59551L19.0215 7.48437H4.97855L9.68967 1.59551C9.93225 1.29227 9.88308 0.849773 9.57989 0.607242C9.27661 0.364617 8.83416 0.413836 8.59163 0.717023L3.1777 7.48437H0.703125C0.314812 7.48437 0 7.79918 0 8.18749V11C0 11.3883 0.314812 11.7031 0.703125 11.7031H1.57903L4.24584 21.0369C4.33205 21.3388 4.60795 21.5469 4.92188 21.5469H19.0781C19.392 21.5469 19.668 21.3388 19.7542 21.0369L22.421 11.7031H23.2969C23.6852 11.7031 24 11.3883 24 11V8.18749C24 7.79918 23.6852 7.48437 23.2969 7.48437ZM18.5478 20.1406H5.45222L3.04153 11.7031H20.9585L18.5478 20.1406ZM22.5938 10.2969C20.1851 10.2969 3.68813 10.2969 1.40625 10.2969V8.89062H22.5938V10.2969Z" fill="#1C2333" />
                    <path d="M12 18.7344C12.3883 18.7344 12.7031 18.4196 12.7031 18.0312V13.8125C12.7031 13.4242 12.3883 13.1094 12 13.1094C11.6117 13.1094 11.2969 13.4242 11.2969 13.8125V18.0312C11.2969 18.4196 11.6117 18.7344 12 18.7344Z" fill="#1C2333" />
                  </svg>

                  <span className="absolute flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-primary-magenta rounded-full right-0 top-0 lg:right-1 lg:top-1">
                    {' '}
                    {cartItems?.length || 0}
                    {' '}
                  </span>
                </div>

              </div>

            </Menu>

            {isMenuOpen && (
              <div>
                <Menu.Items static className="absolute z-50 right-0 mt-2 w-64 origin-top-right divide-y divide-gray-100 rounded-md rounded-br-2xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="flex justify-end">
                    <button type="button" onClick={() => toggleMenu()} className="text-sm py-1 px-2">close(x)</button>
                  </div>

                  {cartItems ? cartItems?.map((item) => (
                    <Menu.Item key={item?.product?.id + 1}>
                      <div className="flex px-4  py-7">
                        <div className="">
                          <img
                            className="object-cover w-16 h-16 rounded-lg"
                            src={item?.product?.media[0]?.file_thumbnail || 'https://via.placeholder.com/150/C4C4C4/000000/?text=kladika.com'}
                            alt=""
                          />
                        </div>
                        <div className="flex-1 ml-5">
                          <div className="relative flex justify-between flex-col h-full sm:gap-x-5">
                            <div className="pr-9 sm:pr-5">
                              <p className="text-[10px] font-bold text-gray-900">
                                {item?.product?.name}
                                {' '}
                                (
                                <span>
                                  x
                                  {item?.total_quantity}
                                </span>
                                )
                              </p>

                            </div>

                            <div className="flex  flex-wrap lg:flex-nowrap  mt-3  items-center sm:mt-0">

                              <div className="flex gap-2 ">
                                {Object.entries(item?.sizes).map(([key]) => (

                                  <div key={key} className="rounded-full ">
                                    <span className="py-1    px-2 text-mainBlack-100 bg-white  cursor-pointer border border-[#CDCDCD]  rounded-full text-[10px] uppercase font-normal">
                                      {' '}
                                      {key}
                                    </span>
                                  </div>
                                ))}

                                <p className="mt-1.5 text-[10px]  text-primary-magenta font-semibold">
                                  {' '}
                                  KES
                                  {' '}
                                  {/* b.product.has_periodic_sale === true && (new Date(b.product.periodic_sale_start_date) < new Date() && new Date(b.product.periodic_sale_end_date) > new Date()) ? a + b.product.periodic_sale_price : a + b.product.price */}
                                  {item?.product?.has_periodic_sale && (new Date(item.product.periodic_sale_start_date) < new Date() && new Date(item.product.periodic_sale_end_date) > new Date()) ? item?.product?.periodic_sale_price : item?.product?.price}
                                  {' '}
                                  <span className="text-[10px] line-through font-normal text-gray-400">
                                    {item?.product?.has_periodic_sale && (new Date(item.product.periodic_sale_start_date) < new Date() && new Date(item.product.periodic_sale_end_date) > new Date()) ? (
                                      <del>
                                        KES
                                        {' '}
                                        {item?.product?.price}
                                      </del>
                                    ) : ''}
                                  </span>
                                </p>
                              </div>

                              <div className="absolute top-0 right-0 flex">
                                <button
                                  onClick={() => handleRemoveFromCart(item)}
                                  type="button"
                                  className="inline-flex p-2 -m-2 text-[#BE3F3F] transition-all duration-200 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:text-gray-900"
                                >
                                  <svg
                                    className="w-3.5 h-3.5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Menu.Item>
                  ))
                    : []}

                  <div className="px-3 py-4 space-y-4">
                    <Menu.Item>
                      <div className="flex justify-between items-center">
                        <p className="text-[10px] text-primary-black uppercase ">Subtotal</p>
                        <p className="text-primary-magenta text-sm uppercase ">
                          KES
                          {cartTotal || 0}
                        </p>
                      </div>
                    </Menu.Item>
                  </div>
                  <div className="px-3 py-4 space-y-4">
                    <Menu.Item>
                      <Button widthClass="w-full" className="justify-center font-semibold text-[10px] bg-gray-300 uppercase" href="/cart">View Cart</Button>

                    </Menu.Item>
                    <Menu.Item>

                      <Button widthClass="w-full" variant="magenta" className="justify-center text-[10px] font-semibold uppercase" href="/checkout">Checkout</Button>

                    </Menu.Item>
                  </div>
                </Menu.Items>
              </div>
            )}
            {singleMenu && (
              <div>
                <Menu.Items static className="absolute z-50 right-0 mt-2 w-64 origin-top-right divide-y divide-gray-100 rounded-md rounded-br-2xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="flex justify-end">
                    <button type="button" onClick={() => toggleMenu()} className="text-sm py-1 px-2">close(x)</button>
                  </div>

                  {singleItem && (
                    <Menu.Item key={singleItem?.product?.id + 1}>
                      <div className="flex px-4  py-7">
                        <div className="">
                          <img
                            className="object-cover w-16 h-16 rounded-lg"
                            src={singleItem?.product?.media[0]?.file_thumbnail || 'https://via.placeholder.com/150/C4C4C4/000000/?text=kladika.com'}
                            alt=""
                          />
                        </div>
                        <div className="flex-1 ml-5">
                          <div className="relative flex justify-between flex-col h-full sm:gap-x-5">
                            <div className="pr-9 sm:pr-5">
                              <p className="text-[10px] font-bold text-gray-900">
                                {singleItem?.product?.name}
                                {' '}
                                (
                                <span>
                                  x
                                  {singleItem?.total_quantity}
                                </span>
                                )
                              </p>

                            </div>

                            <div className="flex  flex-wrap lg:flex-nowrap  mt-3  items-center sm:mt-0">

                              <div className="flex gap-2 ">
                                {Object.entries(singleItem?.sizes).map(([key]) => (

                                  <div key={key} className="rounded-full ">
                                    <span className="py-1    px-2 text-mainBlack-100 bg-white  cursor-pointer border border-[#CDCDCD]  rounded-full text-[10px] uppercase font-normal">
                                      {' '}
                                      {key}
                                    </span>
                                  </div>
                                ))}

                                <p className="mt-1.5 text-[10px]  text-primary-magenta font-semibold">
                                  {' '}
                                  KES
                                  {' '}
                                  {/* b.product.has_periodic_sale === true && (new Date(b.product.periodic_sale_start_date) < new Date() && new Date(b.product.periodic_sale_end_date) > new Date()) ? a + b.product.periodic_sale_price : a + b.product.price */}
                                  {singleItem?.product?.has_periodic_sale && (new Date(singleItem.product.periodic_sale_start_date) < new Date() && new Date(singleItem.product.periodic_sale_end_date) > new Date()) ? singleItem?.product?.periodic_sale_price : singleItem?.product?.price}
                                  {' '}
                                  <span className="text-[10px] line-through font-normal text-gray-400">
                                    {singleItem?.product?.has_periodic_sale && (new Date(singleItem.product.periodic_sale_start_date) < new Date() && new Date(singleItem.product.periodic_sale_end_date) > new Date()) ? (
                                      <del>
                                        KES
                                        {' '}
                                        {singleItem?.product?.price}
                                      </del>
                                    ) : ''}
                                  </span>
                                </p>
                              </div>

                              {/* <div className="absolute top-0 right-0 flex">
                                <button
                                  onClick={() => handleRemoveFromCart(singleItem)}
                                  type="button"
                                  className="inline-flex p-2 -m-2 text-[#BE3F3F] transition-all duration-200 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:text-gray-900"
                                >
                                  <svg
                                    className="w-3.5 h-3.5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                    />
                                  </svg>
                                </button>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Menu.Item>
                  )}

                  {/* <div className="px-3 py-4 space-y-4">
                    <Menu.Item>
                      <div className="flex justify-between items-center">
                        <p className="text-[10px] text-primary-black uppercase ">Subtotal</p>
                        <p className="text-primary-magenta text-sm uppercase ">
                          KES
                          {cartTotal || 0}
                        </p>
                      </div>
                    </Menu.Item>
                  </div> */}
                  <div className="px-3 py-4 space-y-4">
                    <Menu.Item>
                      <Button widthClass="w-full" className="justify-center font-semibold text-[10px] bg-gray-300 uppercase" href="/cart">View Cart</Button>

                    </Menu.Item>
                    <Menu.Item>

                      <Button widthClass="w-full" variant="magenta" className="justify-center text-[10px] font-semibold uppercase" href="/checkout">Checkout</Button>

                    </Menu.Item>
                    <Menu.Item>
                      <div className="text-center">
                        <button type="button" onClick={() => setSingleMenu(!singleMenu)} className="justify-center font-semibold text-primary-magenta underline text-[10px] text-center uppercase">Continue Shopping</button>

                      </div>
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </div>
            )}
          </>
        )}

      </Menu>

    </div>
  );
}
