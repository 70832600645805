import {
  Link, NavLink, Route, Switch
} from 'react-router-dom';
import AddShipping from '../../views/Account/AddShipping';
import Details from '../../views/Account/Details';
import Orders from '../../views/Account/Orders';
import Settings from '../../views/Account/Settings';
import Shipping from '../../views/Account/Shipping';
import PageTitle from '../Title/pageTitle';
import MainLayout from './mainLayout';
// import { useDispatch } from 'react-redux';
// import { userActions } from '../../redux/auth/auth.actions';

export default function AccountLayout() {
  // const dispatch = useDispatch();
  // dispatch(userActions.logout());

  return (
    <MainLayout>
      <div className="container  mx-auto ">
        <PageTitle title="My Account " />
        <div className="max-w-5xl mx-auto">
          <div className="flex flex-col lg:flex-row flex-1 py-8 lg:py-16 gap-12">
            <div className="flex flex-row lg:flex-col lg:w-64 md:flex-col">
              <div className="flex  flex-row lg:flex-col flex-grow  overflow-y-auto ">

                <div className="flex flex-row lg:flex-col flex-1 px-3">
                  <div className="space-y-4">
                    <nav className="flex-1 flex flex-row lg:flex-col space-y-2">
                      <NavLink
                        to="/account/details"
                        className="flex whitespace-nowrap items-center px-4 mt-2 py-2.5 text-sm font-semibold  transition-all duration-200  rounded-full group"
                        activeClassName="text-primary-magenta bg-primary-magenta bg-opacity-30"
                      >
                        My details
                      </NavLink>
                      <NavLink
                        to="/account/shipping"
                        replace
                        activeClassName="text-primary-magenta bg-primary-magenta bg-opacity-30"
                        className="flex whitespace-nowrap items-center px-4 py-2.5 text-sm font-semibold  transition-all duration-200  rounded-full group"
                      >
                        My shipping address
                      </NavLink>
                      <NavLink to="/account/orders" className="flex whitespace-nowrap items-center px-4 py-2.5 text-sm font-semibold  transition-all duration-200  rounded-full group" activeClassName="text-primary-magenta bg-primary-magenta bg-opacity-30">
                        My Orders
                      </NavLink>
                      <NavLink
                        to="/account/settings"
                        activeClassName="text-primary-magenta bg-primary-magenta bg-opacity-30"
                        className="flex whitespace-nowrap items-center px-4 py-2.5 text-sm font-semibold  transition-all duration-200  rounded-full group"
                      >
                        Account Settings
                      </NavLink>
                      <Link
                        replace
                        to="/logout"
                        className="flex whitespace-nowrap items-center px-4 py-2.5 text-sm font-semibold  transition-all duration-200  rounded-full group"
                      >
                        Logout
                      </Link>
                    </nav>

                  </div>

                </div>
              </div>
            </div>

            <div className="flex flex-col flex-1 bg-[#F9FAFB] rounded-3xl">
              <main>
                <div className="py-6">
                  <div className="px-4 mx-auto max-w-5xl sm:px-6 md:px-8">
                    <Switch>
                      <Route exact path="/account/details">
                        <Details />
                      </Route>
                      <Route path="/account/shipping">
                        <Shipping />
                      </Route>
                      <Route path="/account/orders">
                        <Orders />
                      </Route>
                      <Route path="/account/settings">
                        <Settings />
                      </Route>
                      <Route path="/account/new-shipping">
                        <AddShipping />
                      </Route>
                    </Switch>

                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>

      </div>
    </MainLayout>
  );
}
