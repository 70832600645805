/* eslint-disable no-unused-vars */
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { userActions } from '../../redux/auth/auth.actions';
import { profileActions } from '../../redux/user/user.actions';
import { shippingActions } from '../../redux/shipping/shipping.actions';
import Interceptor from '../../Services/Interceptor';
import AddShipping from './AddShipping';

export default function Shipping() {
  const dispatch = useDispatch();
  const { shippingAddress } = useSelector((state) => state.shippingReducer);
  // const details = useSelector((state) => state.userReducer);
  const [disabled, setDisabled] = React.useState(true);

  useEffect(() => {
    dispatch(shippingActions.getShippingAddress());
  }, []);

  const setDefault = (id) => {
    Interceptor.post(`/customer/shipping/addresses/${id}/set-default`)
      .then((res) => {
        dispatch(shippingActions.getShippingAddress());
      });
    // dispatch(shippingActions.updateShippingAddress({ default: true }));
  };

  const deleteAddress = (id) => {
    Interceptor.delete(`/customer/shipping/addresses/${id}`)
      .then((res) => {
        dispatch(shippingActions.getShippingAddress());
      });
  };

  return (
    <div>
      <div className="py-6">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold text-primary-magenta">MY SHIPPING ADDRESS</h2>
          <Link to="/account/new-shipping" className="text-sm font-semibold text-primary-magenta inline-flex items-center">
            <FaPlus />
            {' '}
            Add Address
          </Link>
        </div>
        {/* <AddShipping /> */}
        <div className="mt-10">
          <div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              {shippingAddress?.length > 0 ? shippingAddress.map((address) => (
                <div key={address.id} className="rounded-lg  bg-white flex flex-col justify-between relative border-gray-500 border">
                  <div className="p-6 space-y-">
                    <p className="text-gray-500 capitalize text-sm">{address.street_address}</p>
                    <p className="text-gray-500 capitalize text-sm">
                      {address.city}
                      ,
                      {' '}
                      {address.country}
                    </p>

                    <p className={`${address.is_default === true ? 'text-green-400' : ''} mt-4`}>
                      {address.is_default === true ? 'Default Address' : null}
                      <span />
                    </p>
                  </div>

                  <div className="border-t flex justify-between items-center px-6 py-3">
                    <button type="button" onClick={() => setDefault(address?.id)} className={`${address.is_default === true ? 'hidden' : ''} text-sm font-semibold text-primary-magenta uppercase`}>Set as default</button>
                    <div className="space-x-3 flex ">
                      <Link to={`/account/shipping/${address.id}`} className="text-sm font-semibold text-primary-magenta"><svg aria-label="edit" className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" /></svg></Link>
                      <button type="button" onClick={() => deleteAddress(address?.id)} className="text-sm font-semibold text-primary-magenta"><svg aria-label="delete" className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" /></svg></button>
                    </div>

                  </div>
                </div>
              )) : (
                <div className="rounded-lg  bg-white flex flex-col justify-between relative border-gray-500 border">
                  <div className="p-6 space-y-">
                    <p className="text-gray-500 capitalize text-sm">No saved shipping addres</p>

                  </div>

                </div>
              )}

            </div>
          </div>
        </div>

      </div>

    </div>
  );
}
