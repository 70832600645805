/* eslint-disable default-param-last */
import { AUTH_TYPES, AUTH_STATES } from './auth.types';

const INITIAL_STATE = {
  user: {},
  loading: false,
  error: null,
  state: AUTH_STATES.LOGOUT
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_TYPES.LOGIN_PROCESS:
      return {
        ...INITIAL_STATE,
        loading: true,
        error: null,
        state: AUTH_STATES.LOGIN,
        user: {}
      };
    case AUTH_TYPES.LOGIN_SUCCESS:
      return {
        ...INITIAL_STATE,
        loading: false,
        user: action.payload,
        state: AUTH_STATES.LOGIN,
        error: null
      };
    case AUTH_TYPES.LOGIN_FAILURE:
      return {
        ...INITIAL_STATE,
        loading: false,
        error: action.payload,
        state: AUTH_STATES.LOGIN,
        user: {}
      };
    case AUTH_TYPES.GET_USER_PROCESS:
      return {
        ...INITIAL_STATE,
        loading: true,
        error: null,
        state: AUTH_STATES.GET_USER,
        user: {}
      };
    case AUTH_TYPES.GET_USER_SUCCESS:
      return {
        ...INITIAL_STATE,
        loading: false,
        user: action.payload,
        state: AUTH_STATES.GET_USER,
        error: null
      };
    case AUTH_TYPES.GET_USER_FAILURE:
      return {
        ...INITIAL_STATE,
        loading: false,
        error: action.payload,
        state: AUTH_STATES.GET_USER,
        user: {}
      };
    case AUTH_TYPES.LOGOUT_PROCESS:
      return {
        ...INITIAL_STATE,
        loading: true,
        error: null,
        state: AUTH_STATES.LOGOUT,
        user: {}
      };
    case AUTH_TYPES.LOGOUT_SUCCESS:
      return {
        ...INITIAL_STATE,
        loading: false,
        error: null,
        state: AUTH_STATES.LOGOUT,
        user: {}
      };
    case AUTH_TYPES.LOGOUT_FAILURE:
      return {
        ...INITIAL_STATE,
        loading: false,
        error: action.payload,
        state: AUTH_STATES.LOGOUT,
        user: {}
      };

    case AUTH_TYPES.REGISTER_PROCESS:
      return {
        ...INITIAL_STATE,
        loading: true,
        error: null,
        state: AUTH_STATES.REGISTER,
        user: {}
      };
    case AUTH_TYPES.REGISTER_SUCCESS:
      return {
        ...INITIAL_STATE,
        loading: false,
        error: null,
        state: AUTH_STATES.REGISTER,
        user: action.payload
      };
    case AUTH_TYPES.REGISTER_FAILURE:
      return {
        ...INITIAL_STATE,
        loading: false,
        error: action.payload,
        state: AUTH_STATES.REGISTER,
        user: {}
      };
    default:
      return state;
  }
};

export default authReducer;
