/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-use-before-define */
import { USER_TYPES } from './user.types';
import { userRequests } from '../../Services/user.requests';
import { alertActions } from '../alert/alert.actions';
import { userActions } from '../auth/auth.actions';

export const profileActions = {
  updateUser,
  // facebookLogin,
  // googleLogin
};

function updateUser(data) {
  const process = () => ({
    type: USER_TYPES.UPDATE_USER_DETAILS_PROCESS,
    payload: {}
  });
  const success = (payload) => ({
    type: USER_TYPES.UPDATE_USER_DETAILS_SUCCESS,
    payload
  });
  const failure = (errors) => ({
    type: USER_TYPES.UPDATE_USER_DETAILS_FAILURE,
    payload: errors
  });

  return async (dispatch) => {
    dispatch(process());

    const res = await userRequests.updateUser(data);
    if (res.success) {
      dispatch(success(res.data.data.user));
      dispatch(userActions.user());
      dispatch(alertActions.success(res.data.message));
    } else {
      dispatch(failure(res.error));
      dispatch(alertActions.error(res.error.message));
    }
  };
}
