/* eslint-disable no-plusplus */
// Convert 24-hour time to 12-hour time
// Get user state from selectot
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { AUTH_STATES } from '../redux/auth/auth.types';

// Generate UUID
export const timeFormat = (time) => {
  if (time) {
    return moment(time).fromNow();
  }
};

export const useLocalStorage = (storageKey, fallbackState) => {
  const [value, setValue] = React.useState(
    JSON.parse(localStorage.getItem(storageKey)) ?? fallbackState
  );

  React.useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [value, setValue];
};

export const dateFormat = (date) => {
  if (date) {
    return moment(date).format('Do MMMM YYYY');
  }
};

export const format24HourTime = (time) => {
  // eslint-disable-next-line radix
  let hour = parseInt(time.substr(0, 2));
  let minute = time.substr(3, 2);
  const ampm = hour >= 12 ? 'pm' : 'am';
  hour %= 12;
  hour = hour || 12; // the hour '0' should be '12'
  minute = minute < 10 ? minute : `0${minute}`;
  const strTime = `${hour}:${minute} ${ampm}`;
  return strTime;
};

// Encode to base64
export const base64Encode = (data) => {
  data = JSON.stringify(data);
  return btoa(data);
};

// Decode from base64
export const base64Decode = (data) => {
  data = atob(data);
  return JSON.parse(data);
};

// Set cookie
/**
 *
 * @param {string} name - The name of the cookie
 * @param {string} value - The value of the cookie
 * @param {int} days - The number of days until the cookie expires
 */
export const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name
     }=${
     value || ''
     }${expires
     }; path=/; domain=.${
     process.env.REACT_APP_DOMAIN_NAME}`;
};

// Get cookie
export const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

// Delete cookie
export const deleteCookie = (name) => {
  setCookie(name, '', -1);
};

// Get a parameter from the URL
export const getSearchParam = (parameterName) => {
  let result = null,
    tmp = [];
  const items = window.location.search.substr(1).split('&');
  for (let index = 0; index < items.length; index++) {
    tmp = items[index].split('=');
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  return result;
};

export const getUserState = (state) => {
  const data = {
    isLoggedIn: state.state === AUTH_STATES.LOGIN && !state.error && !state.loading,
    isLoggedOut: state.state === AUTH_STATES.LOGOUT && !state.error && !state.loading,
    isLoggingIn: state.state === AUTH_STATES.LOGIN && !state.error && state.loading,
    isLoggingOut: state.state === AUTH_STATES.LOGOUT && !state.error && state.loading,
    isLoggingInError: state.state === AUTH_STATES.LOGIN && state.error && !state.loading,
    isLoggingOutError:
      state.state === AUTH_STATES.LOGOUT && state.error && !state.loading,
    user: state.user,
    error: state.error
  };
  return data;
};

// Get youtube thumbnail from url
export const getYoutubeThumbnail = (url) => {
  const videoId = url.split('/').pop();
  return `https://img.youtube.com/vi/${videoId}/0.jpg`;
};

export const isImage = (url) => url.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null;

export const getErrorMessage = (err) => {
  const defaultMessage = 'An error occurred. Please try again later, or contact support.';
  if (err.response) {
    if (err.response.data) {
      if (err.response.data.errors) {
        return Object.values(err.response.data.errors)[0][0];
      }
      return err.response.data.message;
    }
    return err.response.message || err.response.statusText || defaultMessage;
  }
  return err.message || defaultMessage;
};
export const generateUUID = () => uuidv4();

export const isListingPropertyExtra = (propertibleType) => propertibleType.endsWith('ListingCategoryPropertyExtra');

export const exportCSV = (data, filename) => {
  const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
  // IE11 & Edge
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(csvData, filename);
  } else {
    // In FF link must be added to DOM to be clicked
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(csvData);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const getDaytime = () => {
  const hour = new Date().getHours();
  if (hour >= 0 && hour < 12) {
    return 'morning';
  } if (hour >= 12 && hour < 17) {
    return 'afternoon';
  }
  return 'evening';
};

/**
 * @param {imageSrc} url
 * @param {x} number
 * @param {y} number
 * @param {width} number
 * @param {height} number
 * @return {dataUrl} base64
 * */
export const getCroppedImage = (imageSrc, x, y, width, height) => {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  const img = new Image();
  img.src = imageSrc;
  ctx.drawImage(img, x, y, width, height, 0, 0, width, height);
  return canvas.toDataURL('image/jpeg');
};

export const blobToFile = (blob, fileName) => {
  const file = new File([blob], fileName, { type: 'image/png' });
  return file;
};

export const Direction = {
  Right: 'to right',
  Left: 'to left',
  Down: 'to bottom',
  Up: 'to top'
};

export function getTrackBackground({
  values,
  colors,
  min,
  max,
  direction = Direction.Right,
  rtl = false
}) {
  if (rtl && direction === Direction.Right) {
    direction = Direction.Left;
  } else if (rtl && Direction.Left) {
    direction = Direction.Right;
  }
  // sort values ascending
  const progress = values.slice(0).sort((a, b) => a - b).map((value) => ((value - min) / (max - min)) * 100);
  const middle = progress.reduce(
    (acc, point, index) => `${acc}, ${colors[index]} ${point}%, ${colors[index + 1]} ${point}%`,
    ''
  );
  return `linear-gradient(${direction}, ${colors[0]} 0%${middle}, ${colors[colors.length - 1]
    } 100%)`;
}

export const useScript = (url, onload) => {
  React.useEffect(() => {
    const script = document.createElement('script');

    // add the url parameter to the script src, don't worry it will get clear later
    script.src = url;
    // set onload of script to the onload parameter
    script.onload = onload;

    // add the script to the document
    document.head.appendChild(script);

    return () => document.head.removeChild(script);
  }, [url, onload]);
};
