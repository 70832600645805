/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-use-before-define */
// import { Redirect } from 'react-router';
import CART_TYPES from './cart.types';

export const cartActions = {
  localStorageUpdate
};

function localStorageUpdate(val, from) {
  return (dispatch) => {
    dispatch({
      type: CART_TYPES.CART_UPDATED,
      payload: { val, from }
    });
  };
}
