import { Link } from 'react-router-dom';
import { getToken } from '../../Services/helpers';

export default function AccountMenu() {
  const token = getToken();

  return (
    <div className=" rounded lg:px-8 lg:py-12 bg-transparent lg:bg-primary-magenta lg:block">
      <div className="px-4 mx-auto   max-w-7xl">
        <div className="">
          <div>
            <ul className=" space-y-2">
              <li>
                <Link to={token ? '/account/details' : '/login'} title="" className="text-sm font-medium lg:text-[#D1D5DB]  transition-all duration-200  hover:text-white ">
                  My Account
                </Link>
              </li>
              <li>
                <Link to="/track-order" title="" className="text-sm font-medium lg:text-[#D1D5DB]  transition-all duration-200  hover:text-white ">
                  Order Tracking
                </Link>
              </li>
              <li>
                <Link to="/checkout" title="" className="text-sm font-medium lg:text-[#D1D5DB]  transition-all duration-200  hover:text-white">
                  Checkout
                </Link>
              </li>

            </ul>
          </div>

        </div>
      </div>
    </div>
  );
}
