/* eslint-disable default-param-last */
import { SHIPPING_TYPES, SHIPPING_STATES } from './shipping.types';

const INITIAL_STATE = {
  user: {},
  shippingAddress: [],
  loadingShipping: false,
  error: null,
  state: SHIPPING_STATES.LOGOUT
};

const shippingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHIPPING_TYPES.UPDATE_SHIPPING_DETAILS_PROCESS:
      return {
        ...INITIAL_STATE,
        loadingShipping: true,
        error: null,
        state: SHIPPING_STATES.UPDATE_SHIPPING_DETAILS,
        shippingAddress: []
      };
    case SHIPPING_TYPES.UPDATE_SHIPPING_DETAILS_SUCCESS:
      return {
        ...INITIAL_STATE,
        loadingShipping: false,
        shippingAddress: action.payload,
        state: SHIPPING_STATES.UPDATE_SHIPPING_DETAILS,
        error: null
      };
    case SHIPPING_TYPES.UPDATE_SHIPPING_DETAILS_FAILURE:
      return {
        ...INITIAL_STATE,
        loadingShipping: false,
        error: action.payload,
        state: SHIPPING_STATES.UPDATE_SHIPPING_DETAILS,
        shippingAddress: []
      };
    case SHIPPING_TYPES.GET_SHIPPING_DETAILS_PROCESS:
      return {
        ...INITIAL_STATE,
        loadingShipping: true,
        error: null,
        state: SHIPPING_STATES.GET_SHIPPING_DETAILS,
        shippingAddress: []
      };
    case SHIPPING_TYPES.GET_SHIPPING_DETAILS_SUCCESS:
      return {
        ...INITIAL_STATE,
        loadingShipping: false,
        shippingAddress: action.payload,
        state: SHIPPING_STATES.GET_SHIPPING_DETAILS,
        error: null
      };
    case SHIPPING_TYPES.GET_SHIPPING_DETAILS_FAILURE:
      return {
        ...INITIAL_STATE,
        loadingShipping: false,
        error: action.payload,
        state: SHIPPING_STATES.GET_SHIPPING_DETAILS,
        shippingAddress: []
      };
    default:
      return state;
  }
};

export default shippingReducer;
